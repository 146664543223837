<template>
    <div>
        <v-row>
            <v-col cols="12">
                <label>{{required ? label + '*' : label}}</label>
                <p v-if="instructions" v-html="instructions" class="instructions"></p>
                <v-card class="mb-4">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-for="t in tableProps" :key="t.Name">
                                        {{t.Name}}
                                    </th>
                                    <th>
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="newValue && newValue.length == 0">
                                    <td :colspan="tableProps.length + 1" class="text-center">
                                        No Records Added
                                    </td>
                                </tr>
                                <template v-else>
                                    <tr v-for="(item, index) in newValue" :key="index">
                                        <td v-for="t in tableProps" :key="t.Name">
                                            {{ displayValue(item, t.Value, t.Format) }}
                                        </td>
                                        <td class="text-right" style="width: 100px;">
                                            
                                            <v-btn icon color="secondary" :disabled="formActive" @click="openEditForm(index)" height="26" width="26" class="mr-2" v-show="!disabled">
                                                <i class="fas fa-pen"></i>
                                            </v-btn>
                                            <v-btn icon color="secondary" :disabled="formActive" @click="removeItem(index)" height="26" width="26" class="mr-2" v-show="!disabled">
                                                <i class="fas fa-times"></i>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </template>
                                
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
                
            </v-col>
        </v-row>
        <ValidationObserver tag="span" v-slot="{}" ref="observer" v-if="formActive">
            <br>
            <form @submit.prevent="validate()">
                <div v-for="(control) in controls" :key="control.name">
                    <DynamicFormControl
                        :module="module"
                        :control="{
                            ...control,
                            value: prefilledControlValue(control.name)
                        }"
                        v-if="showControl(control)" 
                        @change="updateValue" 
                    />
                </div>
                
                <br>
                <div class="text-right">
                    <FormButton v-bind="{label: 'Cancel', color: 'secondary', type: 'button', text: true}" @click="closeForm"></FormButton>
                    <FormButton v-bind="{label: saveButtonText, color: 'secondary', type: 'submit'}" class="ml-2"></FormButton>
                </div>
            </form>
        </ValidationObserver>

        <div v-if="!formActive">
            <br>
            <div v-if="max && newValue.length >= max">
                <p class="mb-0">You have entered the maximum number of entries allowed ({{max}}).</p>
            </div>
            <div v-else class="text-right">
                <FormButton v-bind="{label: addButtonText, color: 'secondary', type: 'button'}" @click="openAddForm" :disabled="disabled"></FormButton>
            </div>
            
        </div>


        <div v-if="formActive" class="hasError">
            <ValidationProvider rules="subFormTableOpen" v-slot="{ errors }">
                <input v-model="formActive" hidden />
                <ErrorMessages :errors="errors"></ErrorMessages>
            </ValidationProvider>
        </div>



        

        <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from '@/components/form/FormButton.vue'
import { showControlHelper } from '@/scripts/show'
import { formatMoney } from '@/scripts/helper'

export default {
    name: 'SubFormTable',
    components: {
        DynamicFormControl,
        FormButton
    },
    data() {
        return {
            formActive: false,
            formState: null,
            activeItemIndex: -1,
            newValue: [],
        }
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        module: String,
        rules: String,
        label: String,
        instructions: String,
        required: Boolean,
        disabled: Boolean,
        max: Number,
        addButtonText: {
            type: String,
            default: 'Add'
        },
        saveButtonText: {
            type: String,
            default: 'Save'
        },
        tableProps: {
            type: Array,
            default: () => []
        },
        controls: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'change'
    ],
    methods: {
        showControl(control) {
            if (!control.config.show) return true;
            //const show =  eval(control.config.show);
            //return show;
            //return true;

            return showControlHelper(null, control.config.show, this.formState, control.name);
        },
        updateValue(data) {
            this.formState[data.name] = data.value;
        },
        onSubmit() {
            if (this.activeItemIndex == -1) {
                this.newValue.push(this.formState);
            } else {
                this.newValue[this.activeItemIndex] = this.formState;
            }

            this.$emit('change', this.newValue)    
            this.closeForm();
        },
        validate() {
            this.$refs.observer.validate()
            .then(valid => {

                if (valid) {
                    this.onSubmit();
                }
            });
        },
        transformIsoDate(date) {
            const newData = new Date(date).toISOString()
            const year = newData.slice(0, 4)
            const month = newData.slice(5, 7)
            const day = newData.slice(8, 10)
            return `${month}/${day}/${year}`
        },
        displayValue(item, Value, Format) {
            let v = {...item};
            const props = Value.split('.');
            props.forEach(p => {
                v = v[p];
            })

            if (Format) {
                if (Format == 'Currency') {
                    return formatMoney(v);
                }
            }
            
            if (Value === 'Date' && this.module === 'endorsementForm') {
                return v ? this.transformIsoDate(v): '';
            }

            return v;
        },
        removeItem(index) {
            this.newValue.splice(index, 1);
            this.$emit('change', this.newValue);
        },
        openEditForm(index) {
            this.formState = {...this.newValue[index]};
            this.formActive = true;
            this.activeItemIndex = index;
        },
        openAddForm() {
            this.formState = {};
            this.formActive = true;
            this.activeItemIndex = -1;
        },
        closeForm() {
            this.formActive = false;
            this.activeItemIndex = -1;
            this.formState = null;
        },
        prefilledControlValue(name) {
            if (!this.formState) return null;
            return this.formState[name];
        }

    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (newVal && newVal.length) {
                    this.newValue = newVal;
                } else {
                    this.newValue = [];
                }
                
            }
        }
    },

}
</script>

<style scoped lang="scss">
@import '@/style.scss';

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .instructions {
        font-size: .8rem;
    }

    ::v-deep .v-data-table tbody {
        tr:nth-child(2n + 1) {
            background-color: #F7FAFF;
        }
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > td, 
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        height: 60px;
    }

</style>